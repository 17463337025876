import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
  signal,
} from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { RoamAclService } from "@app/core/access-control/+data-access";
import { AuthStore } from "@app/core/auth";
import { iconLib } from "@app/core/const/roam-icon";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { NotificationsPanelService } from "@app/pages/notification/+data-access";
import { InformationStore } from "@app/pages/settings-global/organization/information/+data-access/information.store";
import { IModule } from "@app/shared/interfaces";
import { IPageType } from "@app/shared/interfaces/page.interface";
import { IRoamNavigation } from "@app/shared/interfaces/sidebar-menu.interface";
import { LogService } from "@app/shared/services/log.service";
import { RoamNavigationService } from "@app/shared/services/roam-navigation.service";
import {
  PageName,
  getSubMenusBySlug,
  modulesBottom,
  subMenus,
  submenuContactsForVendor,
  submenuJobsForVendor,
} from "@app/utils/data/menu";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subscription, filter, map, take } from "rxjs";
import { MenuStore } from "./+data-access/menu.store";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  animations: [
    trigger("widthGrow", [
      state(
        "open",
        style({
          zIndex: 1,
          width: "66px",
        })
      ),
      state(
        "closed",
        style({
          zIndex: 2,
          width: "100%",
        })
      ),
      transition("open <=> closed", [animate("0.2s")]),
    ]),
  ],
  providers: [MenuStore],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Output()
  public shortcutChange: EventEmitter<void>;

  @Input()
  public modules: IModule[] = [];

  subMenus: any = subMenus;
  modulesBottom: IModule[] = modulesBottom;
  public pageName!: string;
  public user = inject(AuthStore).getAuthUser();
  public isBoardUser = inject(AuthStore).getBoardUser();
  public isAdminUser = inject(AuthStore).getAdminUser();
  public userRole: string | undefined = "";
  public userSubRole: string | undefined = "";
  public isSecondMenuOpen: boolean = false;
  public currentPage: string = "";
  public showShortcutMenu: boolean = false;
  public additionalModules: Partial<IModule>[] = [];
  public searchValue: string = "";
  public icon = iconLib;
  public hoverPage: string = "";

  private associationMenus: IPageType[] = [];
  private subscribers: Subscription[] = [];

  sidebarMenus = signal<IPageType[]>([]);
  sidebarStore = inject(MenuStore);
  notificationsPanelApi = inject(NotificationsPanelService);

  constructor(
    private router: Router,
    private userConfig: UserConfigStore,
    private deviceService: DeviceDetectorService,
    private logService: LogService,
    public navigationService: RoamNavigationService,
    public informationStore: InformationStore
  ) {
    this.shortcutChange = new EventEmitter();
    this.setAssociation();
  }

  ngOnDestroy(): void {
    this.subscribers.forEach(each => each.unsubscribe());
  }

  readonly aclService = inject(RoamAclService);

  readonly isBoardMember = toSignal(
    this.aclService
      .isGranted("owner")
      .pipe(map(x => x && this.aclService.hasModuleAccess("board-portal"))),
    { initialValue: false }
  );

  ngOnInit(): void {
    this.aclService
      .isGranted("member")
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        this.subMenus["contacts"] = submenuContactsForVendor;
        this.subMenus["jobs"] = submenuJobsForVendor;
      });

    this.currentPage = window.location.href.split("/")[3];

    if (this.user?.subRole == "manager") {
      this.showShortcutMenu = true;
    }

    this.setActiveMenu();
  }

  private setActiveMenu(): void {
    const sub = this.navigationService.navigation.subscribe(
      (nav: IRoamNavigation) => {
        if (nav) {
          this.currentPage = nav.page;
          this.isSecondMenuOpen = nav.openSecondMenu as boolean;
        }
      }
    );

    this.subscribers.push(sub);
  }

  public onNavigate(slug: PageName, pageName: string = "") {
    this.logService.track("click-sidebar", { slug });
    this.currentPage = "";

    const subMenus = getSubMenusBySlug(slug);
    this.currentPage = slug;
    this.sidebarStore.currentPage.set(slug);

    this.navigationService.shortMenu = this.currentPage;
    this.pageName = pageName;

    if (!subMenus.length) {
      this.isSecondMenuOpen = false;
      this.router.navigate([this.currentPage]);
    } else {
      this.isSecondMenuOpen = true;
    }
  }

  private setAssociation(): void {
    this.associationMenus = this.setAssociationMenu(
      this.userConfig.associationAll()
    );

    this.subMenus["associations"] = [];
    this.subMenus["associations"] = [
      {
        type: "list",
        title: "All Associations",
      },
      ...this.associationMenus,
    ];
  }

  isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }

  public openShortcutMenuHandler(): void {
    this.logService.track("open-shortcut-modal");

    this.shortcutChange.emit();
  }

  private setAssociationMenu(data: any): IPageType[] {
    return data.reduce((result: IPageType[], each: any) => {
      result.push({
        type: "detail",
        title: each.name,
        tableColumns: [],
        action: "",
        saveAction: "",
        dialogTitle: "",
        dialogEditModeTitle: "",
        code: "",
        slug: each.slug,
        name: "",
      });
      return result;
    }, []);
  }

  protected searchAssociations(): void {
    this.subMenus[this.currentPage] = [
      {
        type: "",
        title: "All Associations",
      },
      ...this.associationMenus,
    ].filter(
      (t: any) =>
        t.title.toLowerCase() === "all associations" ||
        t.title.toLowerCase().includes(this.searchValue)
    );
  }

  filterByName(searchTerm: string): void {
    this.subMenus[this.hoverPage] = [
      {
        type: "",
        title: "All Associations",
      },
      ...this.associationMenus,
    ].filter(
      (t: any) =>
        t.title.toLowerCase() === "all associations" ||
        t.title.toLowerCase().includes(searchTerm)
    );
  }

  getIconPath(slug: string): string {
    return `/assets/svg/sidenav/${slug}.svg`;
  }
}
