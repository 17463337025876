import {
  AfterViewInit,
  Component,
  effect,
  ElementRef,
  inject,
  input,
  OnDestroy,
  OnInit,
  signal,
  viewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { Params, Router } from "@angular/router";
import { AuthStore } from "@app/core/auth";
import { iconLib } from "@app/core/const/roam-icon";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { NotificationsPanelService } from "@app/pages/notification/+data-access";
import { IAssociation, IModule, IUser } from "@app/shared/interfaces";
import { LayoutService } from "@app/shared/services";
import { APP_ROUTES } from "@app/utils/routes";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  #destroy = new Subject<void>();
  #auth = inject(AuthStore);
  private router = inject(Router);
  readonly layout = inject(LayoutService);
  readonly userConfig = inject(UserConfigStore);
  readonly user = this.#auth.getAuthUser();
  readonly notificationsPanelApi = inject(NotificationsPanelService);
  readonly modules = input<IModule[]>([]);
  readonly toastrText = {
    managerSelected: "Successfully selected property manager.",
    managerRemove: "Property manager has been deselected.",
    associationSelected: "Successfully selected association.",
    associationRemove: "Association has been deselected.",
  };

  readonly searchManagerForm = new FormControl<string | null>(null);
  readonly #selectedManager = signal<IUser | null>(null);
  readonly manager = this.#selectedManager.asReadonly();
  readonly filteredManagers$ = this.searchManagerForm.valueChanges.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    startWith(this.searchManagerForm.value),
    switchMap(term => {
      return this.userConfig.managers$.pipe(
        map(list => {
          if (!term) return list;
          return list.filter(x =>
            x.name.toLowerCase().includes(term?.toLowerCase())
          );
        })
      );
    })
  );

  readonly searchAssociationForm = new FormControl<string | null>(null);
  readonly #selectedAssociation = signal<IAssociation | null>(null);
  readonly association = this.#selectedAssociation.asReadonly();
  readonly filteredAssociations$ = this.searchAssociationForm.valueChanges.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    startWith(this.searchAssociationForm.value),
    switchMap(term => {
      return this.userConfig.associations$.pipe(
        map(list => {
          if (!term) return list;
          return list.filter(x =>
            x.name.toLowerCase().includes(term?.toLowerCase())
          );
        })
      );
    })
  );

  protected associationTrigger = viewChild("associationTrigger", {
    read: ElementRef,
  });

  get hideBar() {
    return this.user.role === "tenant";
  }

  get icon() {
    return iconLib;
  }

  get managerId(): string {
    return this.manager()?.id || '';
  }

  get associationId(): string {
    return this.association()?.id || '';
  }

  logout() {
    this.#auth.logout();
  }

  selectManager(e: MatSelectChange): void {
    this.userConfig.selectManager(e.value);
  }

  selectAssociation(e: MatSelectChange): void {
    this.userConfig.selectAssociation(e.value);
  }

  ngAfterViewInit(): void {
    merge(
      this.userConfig.managers$.pipe(
        tap(x => this.searchManagerForm[x.length ? "enable" : "disable"]())
      ),
      this.userConfig.associations$.pipe(
        tap(x => this.searchAssociationForm[x.length ? "enable" : "disable"]())
      )
    )
      .pipe(takeUntil(this.#destroy))
      .subscribe();
  }

  private generateQueryParam(): void {
    const queryParams: Params = {
      managerId: this.manager()?.id ?? '',
      associationId: this.association()?.id ?? '',
    };

    this.router
      .navigate(["search"], {
        queryParams,
        queryParamsHandling: "merge",
        onSameUrlNavigation: "reload",
      });
  }

  ngOnInit(): void {
    merge(
      this.userConfig.selectedManager$.pipe(
        distinctUntilChanged(),
        tap(manager => {
          this.#selectedManager.set(manager || null);
          
          if (this.router.url.startsWith("/" + APP_ROUTES.SEARCH))
            this.generateQueryParam();
        })
      ),
      this.userConfig.selectedAssociation$.pipe(
        distinctUntilChanged(),
        tap(association => {
          this.#selectedAssociation.set(association || null);

          if (this.router.url.startsWith("/" + APP_ROUTES.SEARCH))
            this.generateQueryParam();
        })
      )
    )
      .pipe(takeUntil(this.#destroy))
      .subscribe();
  }

  protected onTriggerPing = effect(
    () => {
      const pinged = this.layout.headerPinged();
      const trigger = this.associationTrigger();
      if (trigger?.nativeElement && pinged) {
        trigger.nativeElement.click();
      }
    },
    { allowSignalWrites: true }
  );

  ngOnDestroy(): void {
    this.#destroy.next();
    this.#destroy.complete();
  }
}
