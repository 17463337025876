<ng-container *ngIf="user && !hideBar">
  <mat-toolbar class="navbar header justify-between roam-gap-4">
    <app-global-search class="w-100" [categories]="modules()" />

    <div class="align-center gap-10">

      <mat-form-field appearance="outline" class="roam-field radius-10">
        <mat-select
          class="roam-menu"
          placeholder="View As"
          panelClass="association-select radio-type reverse"
          (selectionChange)="selectManager($event)"
          [value]="managerId">
          <mat-select-trigger class="align-center justify-between">
            {{ manager()?.name || 'Admin' }}
            <span *ngIf="manager()" (click)="
              userConfig.selectManager('');
              userConfig.selectAssociation('')
            ">
              <span
                class="svg-16 d-flex pl-5"
                [inlineSVG]="icon.closeBlack.src"></span>
            </span>
          </mat-select-trigger>
          <div class="search-wrapper">
            <input
              type="search"
              placeholder="Search"
              [formControl]="searchManagerForm" />
            <mat-icon>search</mat-icon>
          </div>
          <mat-option [value]="''">
            <mat-radio-button 
              [value]="''" 
              [checked]="managerId === ''"
              [name]="'manager'">
              <p class="roam-text">Admin</p>
            </mat-radio-button>
          </mat-option>
          @for (man of filteredManagers$ | async; track man.id) {
            <mat-option [value]="man.id">  
              <mat-radio-button 
                [value]="man.id" 
                [checked]="managerId === man.id"
                [name]="'manager'"
              >
                <p class="roam-text">{{ man.name }}</p>
              </mat-radio-button>
            </mat-option>
          }
        </mat-select>
        <button matSuffix mat-icon-button type="button">
          <mat-icon class="arrow-down">expand_more</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline" class="roam-field radius-10">
        <mat-select
          class="roam-menu"
          placeholder="Select Association"
          panelClass="association-select radio-type reverse"
          (selectionChange)="selectAssociation($event)"
          [value]="associationId">
          <mat-select-trigger
            #associationTrigger
            class="align-center justify-between">
            @if (association()?.name) {
              {{ association()?.name }}
            } @else {
              Select All (Company Portfolio)
            }
            <span
              *ngIf="association()"
              (click)="userConfig.selectAssociation('')">
              <span
                class="svg-16 d-flex pl-5"
                [inlineSVG]="icon.closeBlack.src"></span>
            </span>
          </mat-select-trigger>
          <div class="search-wrapper">
            <input
              type="search"
              placeholder="Search"
              [formControl]="searchAssociationForm" />
            <mat-icon>search</mat-icon>
          </div>
          <!-- TODO: restore loader later! -->
          <!-- <mat-option class="mat-loading" *ngIf="loader.association"> -->
          <!--   <div class="align-center font-14px"> -->
          <!--     Loading <app-loader-dot [size]="3"></app-loader-dot> -->
          <!--   </div> -->
          <!-- </mat-option> -->
          <mat-option value="">
            <mat-radio-button 
                [value]="''" 
                [checked]="associationId === ''"
              >
              <p class="roam-text">
                Select All (Company Portfolio)
              </p>
              </mat-radio-button>
          </mat-option>
          @for (ass of filteredAssociations$ | async; track ass.id) {
            <mat-option [value]="ass.id">
              <mat-radio-button 
                [value]="ass.id" 
                [checked]="associationId === ass.id"
              >
                <p class="roam-text">{{ ass.name }}</p>
              </mat-radio-button>
            </mat-option>
          }
        </mat-select>
        <button matSuffix mat-icon-button type="button">
          <mat-icon class="arrow-down">expand_more</mat-icon>
        </button>
      </mat-form-field>
      <span class="vert-separator"></span>
      <button
        mat-icon-button
        (click)="notificationsPanelApi.toggleExpanded()"
        class="notifications-icon-button">
        <mat-icon>
          <span
            class="item-icon"
            inlineSVG="assets/svg/sidenav/bell-inactive.svg"></span>
        </mat-icon>
      </button>
      <div class="roam-mx-2">
        <app-user-action-menu pictureOnly />
      </div>
    </div>
  </mat-toolbar>
</ng-container>
